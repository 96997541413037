<template>
    <div class="bg-white">
        <LazySectionsGeneralNavigation :dark="true"/>
        <div class="bg-white w-10/12 max-w-sm mx-auto pt-48 pb-24 text-black text-center">
            <h1 class="text-center text-4xl mb-12">{{ $t('menu.access') }}</h1>
            <form name="loginForm" action="https://rollinstudios.teamwork.com/launchpad/login/projects" method="post">
                <input type="hidden" name="automaticSubmit" value="true">
                <div class="login-form">
                    <label for="username">{{ $t('login') }}</label><br/>
                    <input type="email" name="username" id="username" required/><br/>
                    <label for="password">{{ $t('password') }}</label><br/>
                    <input type="password" name="password" id="password" required/><br/>
                    <input type="submit" class="bg-black hover:bg-gray-900 text-white border-0 px-8 py-4 rounded-lg cursor-pointer" :value="$t('connect')"/>
                </div>
            </form>
        </div>
        <LazySectionsGeneralFooterMain :light="true"/>
    </div>
</template>

<script setup lang="ts">
import {useI18n} from 'vue-i18n'
import {useHead} from '#imports'

const {t} = useI18n()

useHead({
    title: 'Login',
    meta: [
        {hid: 'description', name: 'description', content: t('pages.home.hero.creative-services.description')},
        {hid: 'og:title', name: 'og:title', content: t('meta.home')},
        {hid: 'og:description', name: 'og:description', content: t('pages.home.hero.creative-services.description')},
        {hid: 'twitter:title', name: 'twitter:title', content: t('meta.home')},
        {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: t('pages.home.hero.creative-services.description')
        },
        {hid: 'og:type', name: 'og:type', content: 'website'},
        {hid: 'og:url', name: 'og:url', content: 'https://www.rollin.org'},
        {hid: 'image', name: 'image', property: 'og:image', content: '/og-image.jpg'},
        {hid: 'og:image', name: 'og:image', content: '/og-image.jpg'},
        {hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image'},
        {hid: 'twitter:url', name: 'twitter:url', content: 'https://www.rollin.org'},
        {hid: 'twitter:image', name: 'twitter:image', content: '/og-image.jpg'},
{hid: 'og:logo', name: 'og:logo', content: '/logo.png'},
        {hid: 'twitter:domain', name: 'twitter:domain', content: 'rollin.org'}
    ],
})
</script>
